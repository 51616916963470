import React, { useState, ReactNode } from "react";
import {
	DataObserveKey,
	VisualComponent,
	ButtonProps,
	Button,
	Tooltip,
	TooltipProps,
} from "@siteimprove/fancylib";

export type CopyButtonProps = {
	children: ReactNode;
	toCopy: string;
	tooltip: string;
	tooltipCopied: string;
	tooltipPlacement?: TooltipProps["placement"];
	variant?: ButtonProps["variant"];
	"data-observe-key"?: string;
} & DataObserveKey &
	VisualComponent;

export function CopyButton(props: CopyButtonProps): JSX.Element {
	const { toCopy, tooltip, tooltipCopied, tooltipPlacement, children, variant } = props;
	const [copied, setCopied] = useState(false);

	return (
		<Tooltip
			variant={{ type: "interactive" }}
			content={copied ? tooltipCopied : tooltip}
			onHide={() => setTimeout(() => setCopied(false), 200)} //wait for transition to update the text
			placement={tooltipPlacement}
		>
			<Button
				onClick={async () => {
					await navigator.clipboard.writeText(toCopy);
					setCopied(true);
				}}
				type="button"
				variant={variant}
			>
				{children}
			</Button>
		</Tooltip>
	);
}
