import React from "react";
import { TextContainer, Ul, Icon, IconCopy, Paragraph } from "@siteimprove/fancylib";
import {
	Knobs,
	Example,
	DocPageMeta,
	InlineMessage,
	ContentSection,
	HeaderSection,
	ImportExample,
	Header,
	Code,
} from "../../../../../src/docs";
import { LabWarning } from "../../../../../src/docs/docs-lab-warning";
import { CopyButton } from "./copy-button";

export const Meta: DocPageMeta = {
	category: "Actions and controls",
	title: "Copy button",
	notepad: "https://hackmd.io/BMw1SyvCQXmFQ21Y7K9yWQ",
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Copy button"
			subTitle="Enable users to instantly copy text or content to their clipboard with a single click."
		/>
		<ContentSection>
			<TextContainer article>
				<LabWarning />
				<ImportExample lab component={CopyButton} />
				<Header.H2>Examples</Header.H2>
				<Header.H2>Basic Usage</Header.H2>
				<Paragraph>
					A standard button labeled "Copy" (not localized). Ideal for short, inline content where
					the copy action is self-evident. For example, use this variant to copy a sharable link
					within an action bar. The tooltip defaults to appearing above the button.
				</Paragraph>
				<Paragraph>
					<b>Interaction</b>:
				</Paragraph>
				<Ul
					items={[
						<>
							<b>Default</b>: No tooltip.
						</>,
						<>
							<b>Hover</b>: Tooltip: "Click to copy".
						</>,
						<>
							<b>Click</b>: Content copied, tooltip: "Copied!".
						</>,
						<>
							<b>Post-Click</b>: Button and tooltip return to default.
						</>,
					]}
				/>
				<Example fn={BasicUsage} />
				<Header.H2>Custom Tooltip Placement</Header.H2>
				<Paragraph>
					Copy Buttons display feedback in a tooltip. The tooltip is not fixed to the button.
					Customize its position to ensure it's always fully visible, especially in areas with
					limited space above the button.
				</Paragraph>
				<Example fn={CustomPlacementExample} />
				<Header.H2>With Icon</Header.H2>
				<Paragraph>
					The button displays a customizable copy icon (e.g., two overlapping squares) instead of
					text. Helpful when space is limited, or for visual emphasis of the copy action.
				</Paragraph>
				<Example fn={WithIconExample} />
				<Header.H2>Properties</Header.H2>
				<Knobs
					component={CopyButton}
					initialProps={{
						toCopy: "Text to be copied",
						tooltip: "Click to copy",
						tooltipCopied: "Copied!",
						children: "Button text",
					}}
				/>
				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Header.H4>General</Header.H4>
					<Paragraph>
						Use <Code>CopyButton</Code> when
					</Paragraph>
					<Ul
						items={[
							<>Users need to quickly duplicate text or data.</>,
							<>The content to copy is not easily selectable (e.g., within a disabled field).</>,
							<>You want to provide a clear, intuitive copy mechanism.</>,
						]}
					/>
					<Header.H4>Placement</Header.H4>
					<Paragraph>
						<Code>CopyButton</Code> is typically used in the following places:
					</Paragraph>
					<Ul
						items={[
							<>
								<b>Inline</b>: Place the button immediately adjacent to the content it copies.
							</>,
							<>
								<b>Custom</b>: Position the button where it's easily discoverable but doesn't
								obstruct the main content, (e.g., in an Action Bar).
							</>,
						]}
					/>
					<Header.H4>Style</Header.H4>
					<Ul
						items={[
							<>
								<b>Siteimprove Design System</b>: Adhere to Siteimprove's guidelines for color,
								typography, and spacing. If you are not using a component from Fancy, match the
								styling of your <Code>CopyButton</Code> to existing components for visual
								consistency.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Ul
						items={[
							<>Content is easily selectable.</>,
							<>Copying would disrupt the user's flow.</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<Paragraph>
						This component comes with built-in accessibility, no extra work required.
					</Paragraph>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Ul
						items={[
							<>No text content needed in the button itself; tooltips handle communication.</>,
						]}
					/>
				</InlineMessage>
			</TextContainer>
		</ContentSection>
	</>
);

const BasicUsage = () => (
	<CopyButton toCopy={"Text to be copied"} tooltip="Click to copy" tooltipCopied="Copied!">
		Copy
	</CopyButton>
);

const CustomPlacementExample = () => (
	<CopyButton
		variant="primary"
		toCopy="Text to be copied"
		tooltip="Click to copy"
		tooltipCopied="Copied!"
		tooltipPlacement="right"
	>
		Copy
	</CopyButton>
);

const WithIconExample = () => (
	<CopyButton
		variant="borderless"
		toCopy="Text to be copied"
		tooltip="Click to copy"
		tooltipCopied="Copied!"
	>
		<Icon>
			<IconCopy />
		</Icon>
	</CopyButton>
);
